import React from "react";
// import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, Typography } from '@material-ui/core';

import { Container, Row, Col, Card, Tab, Nav } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import { GatsbyImage } from "gatsby-plugin-image"

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function Gallery({data: {allFile: { edges }}}) 
{


  const collegeCampus = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/CollegeCampus') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6} className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="College Campus" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const classes = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Classes') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6} className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Classes" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const clinicalCourse = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/ClinicalCourse') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6} className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Clinical Course" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const mootCourt = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/MootCourt') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6} className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Moot Court" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const computerLab = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/ComputerLab') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Computer Lab" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const library = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Library') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Library" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const farewellday = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Farewellday') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Farewellday Celebrations" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const independenceday = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Independenceday') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Independenceday" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const nepalEarth = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/NepalEarth') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Condolence for Nepal Earth Quake" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const yogaDay = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/YogaDay') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="International Yoga day celebration" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const adr2015 = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Adr2015') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="ADR Practicals - 2015" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const adr2016 = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Adr2016') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="ADR Practicals - 2016" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const abdulkalam = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Abdulkalam') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="APJ Abdulkalam Condolence" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const ambedkarJayanthi = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/AmbedkarJayanthi') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Ambedkar Jayanthi" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const gandhiJayanthi = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/GandhiJayanthi') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Gandhi Jayanthi" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const graduationDay = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/GraduationDay') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Independence and GraduationDay" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const mootcourt2015 = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Mootcourt2015') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Mootcourt 2015" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const teachersDay = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/TeachersDay') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Teachers Day" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const thiruvalluvarDay = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/ThiruvalluvarDay') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Thiruvalluvar Day" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )
  const kamarajBirthday = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/KamarajBirthday') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Kamaraj Birthday" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const adish = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/adish') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Lecture by Adish C Aggarwala on International Council of Jurist 2022 (BCI Chairman)" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

  const independence2021 = edges
  .filter(edge => edge.node.relativeDirectory === 'gallery/Independence2021') // You can filter your posts based on some criteria
  .map(edge => 
  // <Galleryphoto key={edge.node} post={edge.node} name="College Campus" />
  <Col md={6}  className="d-flex">
  <Card className="shadow-sm mb-2 bg-light flex-fill" style={{ cursor:'pointer' }} >
<Card.Body>
  <Box align="center">
  <GatsbyImage image={edge.node.childImageSharp.gatsbyImageData} alt="Independance Day 2021" />
  </Box>
</Card.Body>
</Card>
  </Col>
  
  )

    
  
    return (
  <Layout>
    <Seo title="Photo Gallery" description="Photo Gallery of Various Functions" />
    <ThemeProvider theme={theme}>
<Box style={{ position:'relative', bottom:40 }}>
<Container>
    <Row className="bg-white pt-5 pb-3">
      <Col xs={12} className="pb-3">
      <Typography variant="h4" gutterBottom style={{ color:'#453260' }}>GALLERY</Typography>
        </Col>
        
   
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row>
    <Col sm={3}>
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          <Nav.Link eventKey="first"><Typography variant="body2"> College Campus</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second"><Typography variant="body2">Classes</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="third"><Typography variant="body2">Clinical Course</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="fourth"><Typography variant="body2">Moot Court</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="fifth"><Typography variant="body2">Computer Lab</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="sixth"><Typography variant="body2">Library</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="seventh"><Typography variant="body2">Farewellday Celebrations</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="8"><Typography variant="body2">Independenceday</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="9"><Typography variant="body2">Condolence for Nepal Earth Quake</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="10"><Typography variant="body2">International Yoga Day Celebration</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="11"><Typography variant="body2">ADR Practicals - 2015</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="12"><Typography variant="body2">ADR Practicals - 2016</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="13"><Typography variant="body2">APJ Abdulkalam Condolence</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="14"><Typography variant="body2">Ambedkar Jayanthi</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="15"><Typography variant="body2">Gandhi Jayanthi</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="16"><Typography variant="body2">Independence and GraduationDay</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="17"><Typography variant="body2">Mootcourt - 2015</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="18"><Typography variant="body2">Teachers Day</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="19"><Typography variant="body2">Thiruvalluvar Day</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="20"><Typography variant="body2">Kamraj Birthday</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="21"><Typography variant="body2">Lecture by Adish C Aggarwala on International Council of Jurist 2022 (BCI Chairman)</Typography></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="22"><Typography variant="body2">Independance Day 2021</Typography></Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={9}>
      <Tab.Content>
        <Tab.Pane eventKey="first">
          <Row>
        {collegeCampus}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
        <Row>
        {classes}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="third">
        <Row>
        {clinicalCourse}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="fourth">
        <Row>
        {mootCourt}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="fifth">
        <Row>
        {computerLab}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="sixth">
        <Row>
        {library}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="seventh">
        <Row>
        {farewellday}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="8">
        <Row>
        {independenceday}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="9">
        <Row>
        {nepalEarth}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="10">
        <Row>
        {yogaDay}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="11">
        <Row>
        {adr2015}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="12">
        <Row>
        {adr2016}
        </Row>
        </Tab.Pane>

        <Tab.Pane eventKey="13">
        <Row>
        {abdulkalam}
        </Row>
        </Tab.Pane>

        <Tab.Pane eventKey="14">
        <Row>
        {ambedkarJayanthi}
        </Row>
        </Tab.Pane>

        <Tab.Pane eventKey="15">
        <Row>
        {gandhiJayanthi}
        </Row>
        </Tab.Pane>

        <Tab.Pane eventKey="16">
        <Row>
        {graduationDay}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="17">
        <Row>
        {mootcourt2015}
        </Row>
        </Tab.Pane>

        <Tab.Pane eventKey="18">
        <Row>
        {teachersDay}
        </Row>
        </Tab.Pane>

        <Tab.Pane eventKey="19">
        <Row>
        {thiruvalluvarDay}
        </Row>
        </Tab.Pane>

        <Tab.Pane eventKey="20">
        <Row>
        {kamarajBirthday}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="21">
        <Row>
        {adish}
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="22">
        <Row>
        {independence2021}
        </Row>
        </Tab.Pane>
        
        
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>

    </Row>
</Container>
</Box>
</ThemeProvider>
  </Layout>

  
);
}

export const pageQuery = graphql`
  query {
    allFile(filter: {relativeDirectory: {ne: ""}}){
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          relativeDirectory
        }
      }
    }
  }
`
